.contact-us-page-container .other-content {
    padding-bottom: 0;
    padding: 50px;
    max-width: 800px;
    margin: 100px auto;
    font-size: 20px;

    p {
        font-family: "Roboto", sans-serif;
        ;
    }

    .special {
        font-family: 'Libre Baskerville', serif;
        // font-family: 'Playfair Display', serif;
        color: var(--color-main);
        margin-top: -30px;
        // margin-left: 20px;
        margin: -20px 0 40px 50px;
        font-size: 22px;
        font-weight: 500;
        font-style: italic;
    }

    >div {
        margin-bottom: 200px;

        .stay-connected {
            gap: 50px;
            align-items: flex-start;

            >div {
                align-items: center;
                gap: 15px;
            }

            .container-icon {
                width: 40px;
                height: 40px;
                border: 2.5px solid var(--color-main);
                border-radius: 100px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    padding: 7px;

                }

                #wechat-qr {
                    position: absolute;
                    top: 100%;
                    right: -20px;
                    width: 120px;
                    height: 140px;
                    padding-top: 20px;


                    img {
                        background-color: white;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                    }
                }
            }


        }


        .contact-information {
            margin-top: 50px;
            display: flex;
            gap: 50px;
            // justify-content: space-between;
            // justify-content: center;

            .divider {
                width: 3px;
                background-color: var(--color-main);
            }
        }

        .subscribe {

            background-color: var(--color-main);
            // padding: 10px 20px;
            border-radius: 2px;
            margin-top: 20px;
            overflow: hidden;
            display: inline-block;
            transition: height 2s ease-in-out;
            transform: scaleY(1);

            button {
                color: white;
                font-size: 18px;
                border: none;
                background-color: inherit;
                // padding: 10px 20px;
                margin: 10px 20px;

                &:hover {
                    cursor: pointer;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        .subscribe-window {
            overflow: hidden;
            height: auto;
            max-height: 500px;
            transition: max-height 0.25s ease-in-out, opacity 0.25s ease-in-out;
            transform-origin: top;
            opacity: 1;

            span.close {
                color: var(--color-main);
                display: inline-block;

                transform: translateY(-80px);

                &:hover {
                    cursor: pointer;
                }
            }
        }

        h3 {
            margin-bottom: 20px;
            font-size: 24px;
        }

        p {
            line-height: 1.5;
            font-weight: 300;

            a {
                color: black;
            }
        }
    }

    .no-height {
        max-height: 0 !important;
        opacity: 0 !important;
    }

}

@media screen and (max-width: 750px) {
    .contact-us-page-container .other-content {
        padding: 0;

        >div {
            padding: 40px;
            gap: 40px;
            margin-bottom: 70px;

            .contact-information {
                flex-direction: column;
                gap: 20px;
            }

        }

        >p {
            padding: 40px;
        }
    }
}

@media screen and (max-width: 430px) {
    .contact-us-page-container .other-content {
        >div {
            .stay-connected {
                flex-direction: column;
                gap: 20px;
            }

        }
    }
}

@media screen and (max-width: 430px) {
    .contact-us-page-container .other-content {
        .contact-information {
            a {
                font-size: 16px;
            }
        }
    }
}