.about-us-page-container {
  .red-banner-container {
    p {
      text-align: left !important;
    }
  }

  .other-content {
    margin-top: 0;
    padding-bottom: 0px;
    position: relative;

    .container {
      background-color: white;
      width: 100vw;
      padding: 35px 0 0.5px;
      position: relative;
      z-index: 10000;
      // overflow: auto;
      // -webkit-overflow-scrolling: touch;
    }

    .content {
      max-width: 1000px;
      margin: 200px auto;
      padding: 0 40px;
      // transform: translateZ(0);

      p {
        font-size: 20px;
      }
    }

    .subheading {
      margin-bottom: 50px;
      font-size: 36px;
      font-family: "Lato", sans-serif;
    }

    .list {
      display: flex;

      h3 {
        font-size: 28px;
        margin-bottom: 24px;
      }

      p {
        font-weight: 300;
        line-height: 1.5;
      }
    }

    .sticky {
      object-fit: cover;
      width: 100%;
      height: calc(100vh - 114px);
      position: sticky;
      position: -webkit-sticky;
      top: 114px;
      z-index: 1;
    }

    .our-vision-container {
      p {
        font-weight: 300;
        font-family: "Roboto", sans-serif;
        ;
      }
    }

    .our-mission-container {
      z-index: 10001;

      .our-mission-list {
        display: flex;
        gap: 30px;

        .divider {
          width: 3px;
          background-color: var(--color-main);
        }

        .our-mission-item {
          flex: 1;
        }
      }
    }

    .our-values-container {
      z-index: 10002;

      .subheading {
        margin-bottom: 90px;
      }

      .our-values-list {
        display: flex;
        flex-direction: column;
        gap: 80px;

        img {
          width: 60px;
          height: 60px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .about-us-page-container .other-content {
    img {}
  }
}

@media screen and (max-width: 900px) {
  .about-us-page-container {
    .other-content {
      .container {
        width: auto;
        padding: 1px 40px;


      }

      .our-mission-container {
        .our-mission-list {
          flex-direction: column;
        }
      }

      .sticky {
        top: 75px;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .about-us-page-container {
    .other-content {
      .container {
        .content {
          padding: 0;
        }
      }
    }
  }
}