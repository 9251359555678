.img-background-template {
  .background {
    position: fixed;
    top: 75px;
    left: 0;
    z-index: -1;
    // position: relative;

    img,
    video {
      object-fit: cover;
      object-position: 50% 100%;
      width: 100vw;
      height: calc(100vh - 75px * 2);
      // background-color: var(--color-main);
    }

    // .contain-background {
    //     object-fit: cover !important;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     filter: blur(5px);
    //     z-index: 100;
    // }

    .title {
      font-size: 4vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgba(255, 255, 255, 1);
      text-align: center;
      text-shadow: 0 0 30px rgba(0, 0, 0, 1), 0 0 50px rgba(0, 0, 0, 0.75),
        0 0 80px rgba(0, 0, 0, 0.5);
      animation: slideDown 1s ease-out;

      // filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));

      h1 {
        font-weight: 200;
        font-size: inherit;

        p {
          font-size: inherit;
        }
      }
    }

    // .title {
    //     font-size: 3.5vw;
    //     position: absolute;
    //     bottom: 4vw;
    //     left: 4vw;
    //     color: white;
    //     opacity: 0.6;
    //     text-shadow: 0 0 10px rgba(0, 0, 0, 0.451);
    // }
  }
}

.img-background-template.img-red-banner-template-container {
  .background {
    position: fixed;
    z-index: -1;
  }

  .main-content {
    margin-top: 100vh;
    background-color: white;
    // box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.05);

    .red-banner-container {
      position: relative;
      width: 100%;
      background-color: var(--color-main);
      // min-height: 400px;
      padding: 100px 0;
      display: flex;
      align-items: center;
      overflow: hidden;

      div {
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          color: white;
          font-weight: 400;
          font-size: 20px;
          margin: auto;
          padding: 0 150px;
          text-align: center;
          line-height: 1.5;
          margin-bottom: 50px;
          // font-family: "Lato", sans-serif;
          font-family: "Roboto", sans-serif;

          span {
            // font-size: 18px !important;
          }

          b {
            font-size: 1.8rem;
          }

          &:last-of-type {
            margin-bottom: auto;
            // font-family: "Roboto", sans-serif;
          }
        }

        a.button {
          border: 0.5px solid white;
          padding: 10px 20px;
          color: white;
          font-weight: 300;
          border-radius: 5px;
          opacity: 0.8;
          margin-top: 30px;

          &:hover {
            border: 1px solid white;
            font-weight: 500;
          }
        }
      }

      img {
        height: 90%;
        position: absolute;
        bottom: 5%;
        left: 20px;
        opacity: 0.05;
      }
    }
  }
}

.other-content {
  margin-top: 100px;
  padding-bottom: 300px;
}

@keyframes slideDown {
  0% {
    transform: translate(-50%, calc(-50% - 50px));
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 1000px) {
  .img-background-template {
    .background {
      top: 75px;
      height: calc(100vh);

      img,
      video {
        height: calc(100vh - 175px);
        margin-bottom: 75px;
      }

      .title {
        top: calc(50% - 75px);
      }
    }
  }

  .img-background-template.img-red-banner-template-container {
    .main-content {
      .red-banner-container {
        width: 100vw;
        padding: 100px 0;

        div {
          p {
            padding: 0 80px;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .img-background-template {
    .background {
      .title {
        font-size: 7.5vw;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .img-background-template {
    .background {
      img,
      video {
        width: 100%;
        height: calc(100vh - 120px);
      }

      .title {
        font-size: 12vw;
        top: calc(50% - 75px);
      }
    }
  }

  .img-background-template.img-red-banner-template-container {
    .main-content {
      .red-banner-container {
        div {
          p {
            padding: 0 40px;
          }
        }
      }
    }
  }

  .other-content {
    width: 100vw;
  }
}
