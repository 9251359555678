.insights-page {
  .background img {
    object-position: 50% 20%;
  }

  .other-content {
    max-width: 900px;
    margin: 0 auto 300px;
    padding: 0 20px;
    margin-top: 150px;

    > p {
      font-weight: 700;
      color: var(--color-main);
      margin-bottom: 50px;
      opacity: 0.8;
    }
  }
}

.insight-container {
  display: flex;
  margin: 0px auto 200px;
  gap: 60px;
  color: inherit;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 240px;
    // height: 200px;
    // height: 100%;
    object-fit: cover;
    box-shadow: 4px 4px 0px var(--color-main);
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
      font-weight: 700;
      // font-family: 'Times New Roman', serif;
      font-family: "Lato", sans-serif;
      line-height: 1.2;
    }

    p {
      font-family: "Roboto", sans-serif;

      &.sub {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        opacity: 0.9;
        color: var(--color-main);
        // font-family: 'Roboto', sans-serif;
        // font-family: "Libre Baskerville", serif;
        font-style: italic;
      }

      &.preview {
        font-weight: 300;
        line-height: 1.5;
      }
    }
  }
}

.insight-page-container {
  max-width: 900px;
  margin: 250px auto;
  padding: 0 20px;

  strong {
    color: var(--color-main);
    // text-align: center;
    display: block;
    font-size: 19px;
    opacity: 0.8;
  }

  h1 {
    // text-align: center;
    margin: 20px 0 40px;
    // font-family: 'Times New Roman', serif;
    font-family: "Lato", sans-serif;
    // font-family: 'DM Serif Text', serif;
    line-height: 1.2;
    font-weight: 700;
    font-size: 36px;
  }

  h2 {
    // text-align: center;
    margin: 0 0 100px 0px;
    font-family: "Lato", sans-serif;
    // font-family: "Libre Baskerville", serif;
    font-style: italic;
    font-weight: 400;
    color: var(--color-main);
    font-size: 28px;
  }

  h3 {
    margin: 70px 0 20px;
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
    margin: 10px 0 15px;
  }

  img {
    width: 100%;
    padding: 10px 0;
    border: 2px solid var(--color-main);
    border-left: none;
    border-right: none;
    margin: 40px 0 0;
  }

  ul {
    margin-left: 30px;
    list-style: circle;

    li {
      line-height: 1.8;
      font-size: 19px;
    }
  }

  p {
    line-height: 1.8;
    font-size: 19px;
    color: rgb(45, 45, 45);
    // font-family: 'Open Sans', sans-serif;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 20px;

    &.last {
      margin-top: 100px;
    }

    &:has(i) {
      background-color: rgb(245, 244, 239);
      margin: 0 -30px -30px;
      padding: 30px;
      border-radius: 2px;
    }

    &.mid {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    b {
      i {
        font-family: "Times New Roman", Times, serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 1;
        margin: 20px 0;
      }
    }
  }

  .small {
    width: 250px;
    border: none;
    margin: 30px 0 0;
  }

  .fig-desc {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 60px;
    color: var(--color-main);

    a {
      text-decoration: none;
    }
  }

  a {
    color: var(--color-main);
    text-decoration-thickness: 1.5px;
  }
}

@media screen and (max-width: 750px) {
  .insights-page .other-content {
    padding: 0 40px;
  }

  .insight-container {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 80px;

    img {
      width: calc(100% - 4px);
    }
  }

  .insight-page-container {
    margin: 160px auto;
    padding: 0 40px;

    p {
      &:has(i) {
        margin: 0 -20px -20px;
        padding: 20px;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .insight-container {
    div {
      p {
        &.preview {
          display: none;
        }
      }
    }
  }
}

.insight-table {
  border-collapse: collapse;

  td,
  th {
    border: 1px solid rgb(216, 216, 216);
    padding: 10px 16px;
    vertical-align: top;
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 40%;
  }
}
