* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --color-main: #b92d22;
}

main {
  margin-top: 75px;
}

.a-no-decor {
  text-decoration: none;
  color: inherit;
}

.list-no-style {
  list-style: none;
}

.subheading-container {
  margin-bottom: 100px;
}

.subheading {
  font-size: 32px;
  margin-bottom: 32px;
  font-family: "Lato", sans-serif;
}

.flex {
  display: flex;
}

.back-to-home {
  display: block;
  text-align: center;
  padding-bottom: 50px;
  color: var(--color-main);
  // text-decoration: none;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  font-weight: 300;

  &:hover {
    font-weight: 400;
  }
}

.phone-full-width {
  width: 50%;
}

@media screen and (max-width: 480px) {
  .phone-full-width {
    width: 100%;
  }
}