.link-to-container {
  width: 100%;
  display: flex;
  padding-bottom: 0.5px;
  gap: 0.5px;

  .link-to-item {
    flex: 1;
    width: 100%;
    height: 200px;
    position: relative;

    >a {
      text-decoration: none;

      >img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 30%;
        filter: brightness(0.4);
        // transition: zoom 1s ease;
      }

      p {
        position: absolute;
        color: white;
        font-weight: 100;
        font-size: 4vw;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.7;
      }
    }

    a:hover {
      img {
        filter: brightness(0.7);
        // zoom: 1.1;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .link-to-container {
    flex-direction: column;

    .link-to-item {
      flex: unset;
    }
  }
}