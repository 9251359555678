.home-page-container {
  .s {
    font-family: serif;
    font-family: "Libre Baskerville", serif;
    font-style: italic;
    font-size: 22px !important;
    margin-top: -10px !important;
    margin-bottom: 40px !important;
  }

  .other-content {
    background-color: white;
    // margin: 200px auto 0;
    margin-top: 200px;
    width: 100%;
    // max-width: 770px;
    padding: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .block {
      margin: 0 auto;
      margin-bottom: 200px;
      max-width: 770px;
      width: 100%;

      h2 {
        font-weight: 500;
        font-size: 20px;
        // font-family: "Kanit", sans-serif;
        font-family: "Montserrat", sans-serif;
        color: var(--color-main);
        opacity: 0.7;
        align-self: flex-start;
        margin-bottom: 20px;
      }
    }

    .company-features {
      margin: 50px auto 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      // display: grid;
      // grid-template-columns: repeat(2, 35%);
      gap: 150px;
      justify-content: space-between;
      align-items: flex-start;

      .feature {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
          // width: 50px;
          height: 50px;
        }

        div {
          h3 {
            font-size: 26px;
            font-weight: 900;
            font-family: "Lato", serif;
            opacity: 0.8;
            // font-family: "Libre Baskerville", serif;

            margin: 30px 0 20px;
          }

          p {
            font-size: 20px;
            font-weight: 300;
            font-family: "Roboto", sans-serif;
          }
        }
      }

      div {
        h3 {
          margin-bottom: 10px;
          font-size: 24px;
        }

        p {
          font-weight: 200;
        }
      }
    }

    .block.service-block {
      max-width: unset;
      width: 100vw;
      background-color: var(--color-main);
      max-width: unset;
      margin-left: -50px;
      padding: 0 0 200px;
      // margin-bottom: 0; // remove when added Contact form

      h2 {
        max-width: 770px;
        margin: 0 auto;
        color: rgba(255, 255, 255, 0.6);
        padding: 200px 0 20px 0;
      }

      .service-container {
        display: grid;
        grid-template-columns: repeat(2, calc((100% - 100px) / 2));
        gap: 100px;
        row-gap: 160px;
        max-width: 770px;
        margin: 0 auto;
        color: rgba(255, 255, 255, 0.9);

        .service {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h3 {
            font-family: "Lato", serif;
            // font-family: 'Libre Baskerville', serif;
            font-size: 32px;
            margin-bottom: 50px;
          }

          div {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            align-items: flex-start;
          }

          p {
            margin: 0px 0 50px;
            line-height: 1.5;
            font-weight: 300;
            font-size: 18px;
            font-family: "Roboto", sans-serif;
          }

          a {
            color: inherit;
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            // border-bottom: 1px solid white;
            padding: 15px 10px 15px 15px;
            margin-left: -10px;
            background-color: rgba(255, 255, 255, 0.05);
            border-radius: 10px;

            span {
              font-weight: 300;
            }

            &:hover {
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.15);

              span {
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .block.insight-block {
      margin-bottom: 40px;
      .insight-small-block {
        margin: 40px 0 150px 0;

        .insight-title {
          font-weight: 700;
          color: var(--color-main);
          font-size: 24px;
          margin-bottom: 20px;
        }
      }

      h3 {
        margin-bottom: 10px;
      }

      .insight-container {
        margin-bottom: 60px;
      }

      button {
        border: none;
        padding: 10px 30px;
        border-radius: 3px;
        background-color: rgb(245, 244, 239);
        color: rgba(0, 0, 0, 0.55);
        font-weight: 500;
        font-size: 16px;
        margin-left: 50%;
        transform: translateX(-50%);

        a {
          color: inherit;
          text-decoration: none;
        }

        &:hover {
          color: black;
          cursor: pointer;
        }
      }
    }

    .block.contact-block {
      margin-top: 250px;
    }

    .divider {
      width: 100%;
      height: 1px;
      // background-color: var(--color-main);
      background-color: rgb(234, 233, 228);
    }
  }
}

@media screen and (max-width: 900px) {
  .home-page-container .other-content {
    .block {
      width: 80vw;

      &.service-block {
        h2 {
          max-width: unset;
          width: 80vw;
        }

        .service-container {
          width: 80vw;
          grid-template-columns: 1fr;
          // flex-direction: column;
          gap: 150px;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .home-page-container .other-content {
    margin-top: 100px;

    .block {
      width: 100%;

      &.insight-block {
        margin-bottom: 40px;
      }

      &.contact-block {
        margin-top: 100px;
      }
    }
  }
}
