  .about-contact-us {
    // background-color: var(--main);
    width: 100%;
    position: relative;

    .contact-title {

      h2,
      p {
        color: black;
      }
    }

    >.about-subtitle {
      padding: 150px 0 50px 0;
    }

    .about-contact-us-form {
      color: black;
      // width: 45%;
      min-width: 400px;
      // margin: 10px 0 0 150px;
      // margin: 90px auto 10px;
      padding: 30px 0;

      >div {
        width: 100%;
        gap: 8px;

        .form-item {
          flex-grow: 1;
        }

        &.uneven {
          .form-item:first-child {
            flex-grow: 4;
          }
        }
      }

      .form-item {
        margin-bottom: 20px;

        >label {
          display: flex;
          flex-direction: column;
          gap: 5px;

          >span {
            font-weight: 400;
          }

          span,
          input,
          textarea {
            color: black;
          }
        }

        select.txt {
          color: black;
        }
      }

      .txt {
        outline: none;
        border: 1px solid #b92d22;
        // border: 1.5px solid #b031288d;
        width: 100%;
        height: 40px;
        border-radius: 2px;
        padding: 0 10px;
        background-color: transparent;
      }

      .txt:focus {
        outline: 1px solid #b92d22;

      }

      textarea.txt {
        height: 150px;
        padding: 10px;
        resize: none;
      }

      button {
        height: 40px;
        // width: 100px;
        padding: 0 20px;
        border: none;
        outline: none;
        color: white;
        background-color: var(--color-main);
        font-size: 14px;
        border-radius: 2px;
        cursor: pointer;
        margin-left: auto;
      }

      button:hover {
        background-color: white;
        color: var(--color-main);
        border: var(--color-main) solid 1.5px;
      }

      button:disabled {
        cursor: not-allowed;
      }

      button[type="reset"] {
        background: transparent;
      }

      label input~span {
        color: #aaa;
      }

      label input:checked~span {
        color: #333;
      }

      label {
        cursor: pointer;
      }

      input::placeholder,
      textarea::placeholder {
        color: rgba(204, 204, 204, 0.7);
      }

      input:focus::placeholder,
      textarea:focus::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }

      .form-item.button {
        display: flex;
        gap: 10px;
      }

      .form-item.button>button {
        font-weight: 700;
        letter-spacing: 1px;
      }

      textarea {
        font-family: inherit;
      }
    }
  }

  @media screen and (max-width: 430px) {
    .about-contact-us {
      .about-contact-us-form {
        min-width: 0;
        width: 100%;
      }
    }
  }