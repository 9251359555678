@media screen and (max-width: 900px) {
    .service-box-container {
        .extended {
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;

            .title-container {
                p.title {
                    padding: 50px 50px 35px;
                }
            }

            .content {
                padding: 80px 50px 100px;
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .service-box-container {
        .extended {
            .title-container {
                p.title {
                    font-size: 24px;
                }
            }

            .content {
                padding: 50px 50px 100px;
            }
        }
    }
}