.img-background-template.img-white-template-container {
    .background {
        position: relative;
        z-index: 0;
        top: 0;
        left: 0;
        height: 100vh;


        .title {
            top: calc(50% - 114px)
        }

    }

    .main-content {
        position: relative;
        background-color: white;
        overflow: hidden;
        margin-top: -150px;

        img.img {
            width: 40%;
            position: absolute;
            bottom: 0;
            left: 40vw;
            opacity: 0.2;
        }
    }
}

.other-content {
    margin-top: 100px;
    padding-bottom: 300px;
    position: relative;
    z-index: 1000;
}

@media screen and (max-width: 1000px) {
    main {
        margin-top: 75px;
    }
}