.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  z-index: 10000000000;

  img {
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }

  .header-list {
    list-style: none;
    display: flex;
    gap: 32px;

    .header-item {
      font-weight: 700;
      color: var(--color-main);
      position: relative;

      &:hover {
        > a {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 5px;
        }
      }

      &.selected {
        > a {
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 5px;
        }
      }

      .services-container,
      .insights-container {
        position: absolute;
        top: 16px;
        left: -26px;
        list-style: none;
        white-space: nowrap;
        // width: 200px;
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 54px 26px 26px;
        // padding-top: 50px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  main {
    position: relative;
    z-index: 10000;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px 20px;
    z-index: 1000000000;
    // height: 100vh;

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menu-icon {
        color: var(--color-main);

        &:hover {
          cursor: pointer;
        }
      }
    }

    img {
      height: 40px;
    }

    .header-list {
      flex-direction: column;
      height: 0;
      width: 100vw;
      position: absolute;
      top: 75px;
      right: 0;
      padding-right: 25px;
      background-color: white;
      transition: height 0.25s ease-out;
      overflow-y: scroll;
      // overflow: hidden;

      .header-item {
        text-align: center;

        &:first-of-type {
          margin-top: 60px;
        }

        .services-container,
        .insights-container {
          position: unset;
          padding: 26px 26px;

          .header-item {
            font-weight: 500;

            &:first-of-type {
              margin-top: 0px;
            }
          }
        }
      }

      &.open {
        height: calc(100vh - 75px);
        transition: height 0.25s ease-out;
        // display: flex;
      }
    }
  }
}
