.red-line-text {
    display: flex;
    gap: 20px;

    .red-line {
        width: 8px;
        background-color: var(--color-main);
    }

    p {
        line-height: 1.5;
        font-family: "Roboto", sans-serif;
        font-size: 17px;
    }
}

@media screen and (max-width: 430px) {
    .red-line-text {
        width: 100%;

        .red-line {
            width: 3px;
            flex-shrink: 0;
        }
    }
}