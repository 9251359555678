// .service-box-container {

//     position: relative;

//     .folded {

//         width: 100%;
//         height: 100%;

//         p {
//             color: white;
//             font-weight: 900;
//             font-size: 32px;
//             opacity: 0.6;
//             position: absolute;
//             bottom: 10px;
//             left: 10px;
//             text-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
//         }

//         &:hover {
//             cursor: pointer;
//         }
//     }

//     .extended {
//         width: 80vw;
//         height: 75vh;
//         background-color: white;
//         position: fixed;
//         top: calc(114px + 5vh);
//         left: 10vw;
//         z-index: 100000000;
//         // padding: 40px;
//         border-radius: 2px;
//         filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.07));
//         display: flex;
//         flex-direction: column;

//         .title-container {
//             width: 100%;
//             position: relative;

//             border-bottom: solid 6px var(--color-main);

//             img {
//                 position: absolute;
//                 top: 0;
//                 left: 0;
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//                 overflow: hidden;
//                 filter: brightness(0.3);
//                 border-top-left-radius: 2px;
//                 border-top-right-radius: 2px;
//             }

//             p.title {
//                 font-size: 32px;
//                 font-weight: 800;
//                 font-family: "Lato", sans-serif;
//                 color: white;
//                 position: relative;
//                 z-index: 10000;
//                 padding: 50px 150px 35px;
//                 // text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
//                 left: 0;
//                 top: 0;
//             }

//             .close-icon {
//                 color: white;
//                 position: absolute;
//                 top: 20px;
//                 right: 20px;
//                 z-index: 1000000;

//                 &:hover {
//                     cursor: pointer;
//                 }
//             }
//         }

//         .content {
//             height: 100%;
//             padding: 80px 150px 100px;
//             overflow: auto;

//             p {
//                 font-weight: 500;
//                 font-size: 16px;
//                 font-family: "Roboto", sans-serif;
//                 ;
//                 position: relative;
//                 top: 0;
//                 left: 0;
//                 line-height: 2;
//                 margin-bottom: 20px;

//                 &:last-of-type {
//                     margin-bottom: 0;
//                 }
//             }


//         }


//     }

//     .whole-overlay {
//         width: 100vw;
//         height: 100vh;
//         position: fixed;
//         left: 0;
//         top: 0;
//         z-index: 100000;
//         background-color: rgba(0, 0, 0, 0);
//         display: none;
//         transition: all 5s ease;
//     }

//     .whole-overlay.visible {
//         display: block;
//         background-color: rgba(0, 0, 0, 0.3);
//         transition: all 5s ease;
//     }

//     img {
//         // filter: brightness(0.5);
//         // -webkit-filter: brightness(0.5);
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         display: block;
//     }





//     &.hovered .overlay {
//         background-color: transparent;
//     }

//     .overlay {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         pointer-events: none;
//         background-color: rgba(0, 0, 0, 0.6);
//         transition: background-color 0.3s ease;
//     }
// }

.service-box-container {
    img {
        height: 48px;
    }

    p.title {
        color: var(--color-main);
        font-family: "Lato", sans-serif;
        font-size: 26px;
        font-weight: 500;
        margin: 36px 0 24px;
    }

    p {
        font-family: "Roboto", sans-serif;
        margin-bottom: 16px;
        font-size: 17px;
        line-height: 1.5;
    }
}