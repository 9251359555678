.for-suppliers-page-container {
    .background {
        video {
            // object-fit: contain;
            object-position: 50% 0%;
            animation: moveObjectPosition 16s infinite;
        }
    }

    .other-content {

        .service-list {
            // grid-template-rows: repeat(3, 280px);

            .middle-logo {
                background-color: var(--color-main);
                padding: 0 50px;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                }
            }
        }
    }
}


@keyframes moveObjectPosition {
    0% {
        object-position: 50% 100%;
    }

    100% {
        object-position: 50% 0%;
    }
}