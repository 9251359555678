.for-suppliers-developers-page-container .other-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    .subheading-container {
        max-width: 1000px;
    }

    .service-list {
        display: grid;
        grid-template-columns: repeat(3, calc((100% - 96px * 2) / 3));
        // grid-auto-rows: 280px;
        width: 100%;
        max-width: 1000px;
        column-gap: 96px;
        row-gap: 96px;
        margin: 50px auto 200px;
        // padding: 30px;




    }
}

.for-developers-page-container .other-content {
    .service-list {
        // grid-template-rows: repeat(2, 280px);
        grid-template-columns: repeat(2, 45%);

        .service-box-container p {
            // left: 20px;
            bottom: 20px;
        }
    }
}

@media screen and (max-width: 900px) {
    .back-to-home {
        display: none;
    }

    .for-suppliers-developers-page-container .other-content {
        padding-bottom: 1px;

        .subheading-container {
            width: 100vw;
            padding: 0 40px;

            p {
                line-height: 2;
            }
        }

        .service-list {
            grid-template-columns: repeat(2, calc((100% - 96px)) /2);
            // grid-auto-rows: 280px;
        }
    }
}

@media screen and (max-width: 750px) {
    .for-suppliers-developers-page-container .other-content {
        .service-list {
            grid-template-columns: repeat(1, 100%);
            margin-bottom: 120px;
            // padding: 0 30px;
            // grid-auto-rows: 220px;
        }

    }
}