.unsubscribe-container {
    margin: 280px 100px 0;
    min-height: calc(100vh - 420px);
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        text-align: center;
        font-size: 30px;
        margin-bottom: 50px;
    }

    p {
        text-align: center;
        width: 60%;
        margin: 5px 0;
    }

    form {
        h2 {
            font-weight: 400;
            font-size: 18px;
            margin: 50px 0 10px;
        }

        >div {
            display: flex;
            gap: 10px;
        }

        select {
            padding: 0 8px;
            flex-grow: 1;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
            color: #333;
            background-color: #fff;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            position: relative;

            &::-ms-expand {
                display: none;
            }

            &::after {
                content: ">";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        button {
            border: var(--color-main) solid 2px;
            padding: 2px 10px;
            border-radius: 5px;
            font-size: 16px;
            background-color: var(--color-main);
            color: white;
            font-weight: 500;

            &:hover {
                cursor: pointer;
                background-color: transparent;
                color: var(--color-main);
            }
        }

        p.message {}
    }
}

@media screen and (max-width: 750px) {
    .unsubscribe-container {
        margin: 200px 100px 0;
        min-height: calc(100vh - 400px);
    }
}

@media screen and (max-width: 430px) {
    .unsubscribe-container {
        margin: 150px 40px 0;
        min-height: calc(100vh - 75px);

        p {
            width: 100%;
        }

        form {
            h2 {
                text-align: center;
            }

            >div {
                flex-direction: column;
            }
        }
    }

}