.footer-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 36px;
    background-color: var(--color-main);
    color: white;
    padding: 60px 80px 20px;
    overflow: hidden;
    z-index: 100000;
    font-weight: 300;

    .social {
        width: 100%;
        justify-content: space-between;
        position: relative;

        >div {
            display: flex;
            align-items: center;

            img.i {
                filter: brightness(10);
                height: 25px;
                margin-left: 15px;
            }

            img.o {
                position: absolute;
                height: 100px;
                filter: drop-shadow(0 0 10px rgb(0, 0, 0, 0.1));
                border-radius: 2px;
                padding-top: 10px;
                right: -30px;
            }
        }
    }


    .logo {
        width: 130px;
    }

    .content {
        color: inherit;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        ul {

            li {
                line-height: 2;

                a {
                    color: inherit;
                }
            }
        }

        address {
            line-height: 1.5;
            font-style: normal;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    .copyright {
        text-align: center;
        font-size: 0.8rem;
        opacity: 0.55;
    }

    img.background {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0.04;
        z-index: -1;
    }
}

@media screen and (max-width: 800px) {
    .footer-container {
        padding: 60px 30px 20px;
        z-index: 10000;
        align-items: center;
        gap: 50px;

        .social {

            >div {
                display: inline-flex;

                img.o {
                    left: 40px;
                }
            }
        }

        >div {
            display: flex;
            flex-direction: column;
            gap: 50px;
        }

        .content {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 50px;

            address {
                // font-weight: 200;
            }
        }

        .copyright {
            width: 100%;
            border-top: solid rgba(255, 255, 255, 0.481) 0.5px;
            padding-top: 10px;
        }
    }
}