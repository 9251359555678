.work-for-us-page-container .other-content {
    padding: 200px 0;
    width: 50vw;
    min-width: 600px;
    margin: auto;


    div {
        line-height: 1.5;

        p {
            font-weight: 300;
        }

        a {
            color: black;
        }
    }

    .red-line-text {
        gap: 50px;
    }
}

@media screen and (max-width: 430px) {
    .work-for-us-page-container .other-content {
        padding: 100px 40px;
        width: 100%;
        min-width: 0;
    }
}

@media screen and (max-width: 330px) {
    .work-for-us-page-container .other-content {
        padding: 100px 20px;

        .red-line-text {
            gap: 30px;
        }
    }
}